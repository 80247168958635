import React from 'react';
import Layout from 'components/Layout';
import { Link } from 'gatsby';

const NotFoundPage = () => {
  return (
    <Layout pageName="Erreur">
      <div className="about-container">
        <div className="about-container-text">
          <h1>Cette page n&#39;existe pas</h1>
          <p>retourner sur la page <Link to='/'>projets</Link></p>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
